import * as React from "react"

const Container = ({ className, children }) => {
  return (
    <div className={`max-w-6xl mx-auto px-5 lg:px-6 ${className && className}`}>
      {children}
    </div>
  )
}

export default Container
