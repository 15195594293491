import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const LinkButton = ({ text, url, type, size, className, isExternal, target }) => {
  if (isExternal) {
    return (
      <a href={url} target={target}>
        <Button
          text={text}
          type={type}
          size={size}
          className={className}
        />
      </a>
    )
  } else {
    return (
      <Link to={url}>
        <Button
          text={text}
          type={type}
          size={size}
          className={className}
        />
      </Link>
    )
  }
}

const Button = ({ text, type, size, className }) => {
  if (type === "normal") {
    return (
      <div
        className={`text-linkBlue text-sm lg:text-base font-bold pt-3 pb-3 pl-1 pr-1 text-center hover:opacity-80 inline-block ${className && className}`}
      >
        <div className="flex items-center">
          {text}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6667 4.16663L17.5 9.99996M17.5 9.99996L11.6667 15.8333M17.5 9.99996L2.5 9.99996"
              stroke="#3F97F5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    )
  } else {
    const primaryStyle = "text-white bg-linkBlue"
    const secondaryStyle = "text-linkBlue bg-white"
    const sizeS = "text-xs pt-3 pb-3 pl-3 pr-3"
    const sizeM =
      "text-sm lg:text-base lg:w-52 min-w-max px-3 py-3 lg:py-4 lg:px-4"

    return (
      <div
        className={`${type === "secondary" ? secondaryStyle : primaryStyle} ${
          size === "s" ? sizeS : sizeM
        } font-bold rounded text-center hover:opacity-90 inline-block ${className}`}
      >
        {text}
      </div>
    )
  }
}

LinkButton.defaultProps = {
  type: "primary",
  size: "m",
  isExternal: false,
}

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "normal"]),
  size: PropTypes.oneOf(["s", "m"]),
  className: PropTypes.string,
  isExternal: PropTypes.bool,
}

export default LinkButton
